/*
 * Examples
 *
 * Isolated sections of example content for each component or feature. Usually
 * followed by a code snippet.
 */

.bs-example {
  position: relative;
  padding: 45px 15px 15px;
  margin: 0 -15px 15px;
  background-color: #fafafa;
  box-shadow: inset 0 3px 6px rgba(0,0,0,.05);
  border-color: #e5e5e5 #eee #eee;
  border-style: solid;
  border-width: 1px 0;
}
/* Echo out a label for the example */
.bs-example:after {
  content: "Example";
  position: absolute;
  top:  15px;
  left: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #bbb;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Tweak display of the code snippets when following an example */
.bs-example + .highlight {
  margin: -15px -15px 15px;
  border-radius: 0;
  border-width: 0 0 1px;
}

/* Make the examples and snippets not full-width */
@media (min-width: 768px) {
  .bs-example {
    margin-left: 0;
    margin-right: 0;
    background-color: #fff;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 4px 4px 0 0;
    box-shadow: none;
  }
  .bs-example + .highlight {
    margin-top: -16px;
    margin-left: 0;
    margin-right: 0;
    border-width: 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

/* Undo width of container */
.bs-example .container {
  width: auto;
}

/* Tweak content of examples for optimum awesome */
.bs-example > p:last-child,
.bs-example > ul:last-child,
.bs-example > ol:last-child,
.bs-example > blockquote:last-child,
.bs-example > .form-control:last-child,
.bs-example > .table:last-child,
.bs-example > .navbar:last-child,
.bs-example > .jumbotron:last-child,
.bs-example > .alert:last-child,
.bs-example > .panel:last-child,
.bs-example > .list-group:last-child,
.bs-example > .well:last-child,
.bs-example > .progress:last-child,
.bs-example > .table-responsive:last-child > .table {
  margin-bottom: 0;
}
.bs-example > p > .close {
  float: none;
}

.bs-example .nav-tabs {
  margin-bottom: 15px;
}
