/*
 * Side navigation
 *
 * Scrollspy and affixed enhanced navigation to highlight sections and secondary
 * sections of docs content.
 */
// @primary-color hsl(263, 29%, 46%) -> hsl(264, 34%, 36%)

/* By default it's not affixed in mobile views, so undo that */
.bs-sidebar.affix {
  @media (max-width: @screen-sm-max) {
    position: static !important;
  }
}

// @media (min-width: 768px) {
//   .bs-sidebar {
//     padding-right: 20px;
//     margin-right: 21px;
//   }
// }

/* First level of nav */
.bs-sidenav {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 124px;
}

/* All levels of nav */
.bs-sidebar .nav > li > a {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: hsl(0, 0%, 60%);
  padding: 4px 15px;
  text-align: right;
}
.bs-sidebar .nav > li > a:hover,
.bs-sidebar .nav > li > a:focus {
  padding-right: 15px - 1px;
  color: @docs-color;
  text-decoration: none;
  background-color: transparent;
  border-right: 1px solid @docs-color;
}
.bs-sidebar .nav > .active > a,
.bs-sidebar .nav > .active:hover > a,
.bs-sidebar .nav > .active:focus > a {
  padding-right: 15px - 2px;
  font-weight: bold;
  color: @docs-color;
  background-color: transparent;
  border-right: 2px solid @docs-color;
}

/* Nav: second level (shown on .active) */
.bs-sidebar .nav .nav {
  display: none; /* Hide by default, but at >768px, show it */
  padding-bottom: 10px;
}
.bs-sidebar .nav .nav > li > a {
  padding-top:    2px;
  padding-bottom: 2px;
  padding-right: 30px;
  font-size: 12px;
  font-weight: normal;
}
.bs-sidebar .nav .nav > li > a:hover,
.bs-sidebar .nav .nav > li > a:focus {
  padding-right: 29px;
}
.bs-sidebar .nav .nav > .active > a,
.bs-sidebar .nav .nav > .active:hover > a,
.bs-sidebar .nav .nav > .active:focus > a {
  font-weight: 500;
  padding-right: 28px;
}

/* Show and affix the side nav when space allows it */
@media (min-width: 992px) {
  .bs-sidebar .nav > .active > ul {
    display: block;
  }
  .bs-sidebar.affix-bottom .bs-sidenav,
  .bs-sidebar.affix .bs-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  /* Widen the fixed sidebar again */
  .bs-sidebar {
    margin-right: 30px;
  }
}
