/*
 * Callout for 2.3.2 docs
 *
 * Only appears below page headers (not on the homepage). The homepage gets its
 * own link with the masthead links.
 */

.bs-old-docs {
  padding: 15px 20px;
  color: hsl(0, 0%, 47%);
  background-color: hsl(0, 0%, 98%);
  border-top: 1px solid hsl(0, 0%, 100%);
  border-bottom: 1px solid hsl(0, 0%, 90%);
}
.bs-old-docs strong {
  color: hsl(0, 0%, 33%);
}
