/*
 * Footer
 *
 * Separated section of content at the bottom of all pages, save the homepage.
 */

.bs-footer {
  padding-top: 40px;
  padding-bottom: 30px;
  margin-top: 100px;
  color: hsl(0, 0%, 47%);
  text-align: center;
  border-top: 1px solid hsl(0, 0%, 90%);
}
.footer-links {
  margin: 10px 0;
  padding-left: 0;
}
.footer-links li {
  display: inline;
  padding: 0 2px;
}
.footer-links li:first-child {
  padding-left: 0;
}

@media (min-width: 768px) {
  .bs-footer {
    text-align: left;
  }
  .bs-footer p {
    margin-bottom: 0;
  }
}

/*
 * Footer
 *
 * Separated section of content at the bottom of all pages, save the homepage.
 */

// .bs-footer {
//   padding-top: 40px;
//   padding-bottom: 40px;
//   margin-top: 100px;
//   color: #777;
//   text-align: center;
//   border-top: 1px solid #e5e5e5;
// }
// .footer-links {
//   margin-top: 20px;
//   padding-left: 0;
//   color: #999;
// }
// .footer-links li {
//   display: inline;
//   padding: 0 2px;
// }
// .footer-links li:first-child {
//   padding-left: 0;
// }

// @media (min-width: 768px) {
//   .bs-footer p {
//     margin-bottom: 0;
//   }
// }
